<template>
  <div class="main-container">
    <div class="section-topic">
      <div class="title-header">
        <h1>Admin Logs</h1>
      </div>
    </div>
    <div class="section-action">
      <div class="search-action">
        <v-text-field
            class="btn btn-color btn-border btn-border-color"
            outlined
            dense
            prepend-inner-icon="mdi-magnify"
            placeholder="Search"
            v-model="searchItem"
            @keypress.enter="searchFilterItem"
        >
        </v-text-field>
      </div>
      <div class="btn-new-create-action">
        <!--        <v-btn class="btn-create" @click="onCreate()">-->
        <!--          New Position-->
        <!--        </v-btn>-->
      </div>
    </div>
    <div class="section-form-content">
      <div class="block-content">
        <table class="table table-view">
          <thead>
          <tr>
            <th class="text-center">
              User
            </th>
            <th class="text-left">
              Action
            </th>
            <th class="text-left">
              Description
            </th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="item in AdminLogs" :key="item.index">
            <td>
              <div class="company-log">
                <div class="log-image">
                  <div class="log-img">
                    <div class="log-logo">
                      <i class="fas fa-user"></i>
                    </div>
                  </div>
                </div>
                <span class="ml-5">{{ item.user_name }}</span>
              </div>
            </td>
            <td>
              <p>{{ item.description }}</p>
            </td>
            <td>
              <div
                  v-if="
                    item.properties.attributes.name
                  "
              >
                  <span style="white-space: normal"
                  >{{ item.properties.attributes.name }}</span
                  >
              </div>
            </td>
          </tr>
          </tbody>
        </table>
        <Pagination
            class="mt-5"
            v-if="pagination.total_pages > 1"
            :pagination="pagination"
            :offset="offset"
            @paginate="fetchAdminLogs"
        >
        </Pagination>
      </div>
    </div>
  </div>
</template>

<script>
import Pagination from "@/components/Paginate/Pagination";

export default {
  components: {
    Pagination,
  },

  data() {
    return {
      searchItem: "",
      offset: 10,
      pagination: {},
      per_page: 10,
      AdminLogs: [],
    };
  },
  methods: {
    searchFilterItem() {
      this.fetchAdminLogs();
    },

    fetchAdminLogs() {
      this.$axios
          .get(`admin/logs`, {
            params: {
              page: this.pagination.current_page,
              per_page: this.per_page,
              filter: this.searchItem,
            },
          })
          .then((res) => {
            if (res.data.code === 200) {
              this.AdminLogs = res.data.data.data;
              console.log(this.AdminLogs)
              this.pagination = res.data.data.pagination;
              if (!this.AdminLogs.length > 0) {
                this.pagination.current_page = this.pagination.current_page - 1;
              }
            }
          });
    },
  },
  created() {
    this.fetchAdminLogs();
  },
};
</script>

<style scoped lang="scss">
.company-log {
  display: flex;
  align-items: center;
  position: relative;

  .log-image {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #bebbbb;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-size: 30px;

    i {
      font-size: 28px;
      text-align: center;
    }
  }
}
</style>
